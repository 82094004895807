/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-15 14:11:27
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-12 14:13:11
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Table,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { Payment, PaymentStatus } from '../../../models/payment'
import { PrintTemplateCategory } from '../../../models/print'
import { RedeliveryParams } from '../../../services/payment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { serverType } from '../../../services/commodity'
import styles from './InvoiveRecord.module.css'
import { stringTrim } from '../../../utils/StringUtils'

import {
  getVisibleIsPeopleFace,
  getVisibleQrCode,
  invoiceListAsyncForMbfe,
} from '../../print/printSlice'
import { PrintTemplateModal } from '../../print/PrintTemplateModal'
import { setState } from '../../sales/Statement/statementSlice'
import {
  cancelInvoiceAsync,
  cancelInvoiceMbfeAsync,
  exportListHzAsync,
  getInvoicingStatusAsync,
  getInvoicingStatusForMbfeAsync,
  getTicketNumAsync,
  invoiceExportAsync,
  invoicePageAsync,
  redeliveryAsync,
  ticketAccountCheckMbfeAsync,
} from '../confirm/paymentConfirmSlice'
import { Columns } from './columns'
import {
  checkFlushAsync,
  checkFlushForMbfeAsync,
  getDzfpExportAsync,
  invoiceDetailDeleteAsync,
  invoiceDetailStatusAsync,
} from './invoiceSlice'
import { Query } from './Query'
import Qrcode from 'qrcode.react'
import { treatmentDetail } from '../../../services/patient'
import { MbfeService } from '../../../utils/MedicalUtils'
import { selectCredentials } from '../../../app/applicationSlice'
import moment from 'moment'
import { DateTimeFormatSimple } from '../../../models/datetime'
import { RecipeStatus } from '../../../models/recipe'
import { NotInvoicedQueryModal } from './NotInvoicedQueryModal'

let timer: any = null
interface InvoiveRecordProps {
  status: number
  type?: number //口腔版 his 发票明细
  patientId?: string //口腔版 his 发票明细
}

const InvoiveRecord = ({
  status,
  type,
  patientId,
}: InvoiveRecordProps): ReactElement => {
  const theme = useContext(ThemeContext)
  const credentials = useSelector(selectCredentials)

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [params, setParams] = useState<any>({
    current: 0,
    size: 10,
    state: status,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })
  const [timeVisible, setTimeVisible] = useState(false) //发票日对账

  const [checkAccountTime, setCheckAccountTime] = useState<any>()
  const [reasonModal, setReasonModal] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [base64PdfData, setBase64PdfData] = useState('')

  const [againModal, setAgainModal] = useState(false)

  const [failReason, setFailReason] = useState('')

  const [redeliveryData, setRedeliveryData] = useState<RedeliveryParams>()

  const [loading, setLoading] = useState(false)

  const [pageLoading, setPageLoading] = useState(false)

  const [ticketVisible, setTicketVisible] = useState(false)

  const [notInvoicedQueryVisible, setNotInvoicedQueryVisible] = useState<any>()

  const [printId, setPrintId] = useState<any>()

  const [printTemplateCategory, setPrintTemplateCategory] = useState<
    PrintTemplateCategory | undefined
  >()

  const [pText, setPText] = useState<any[]>()

  const [text, setText] = useState('')

  const [nuonuoCode, setNuoNuoCode] = useState<any>()

  const [isQrCodeVisible, setIsQrCodeVisible] = useState(false)

  const [scrollHeight, setScrollHeight] = useState<any>()

  const [isQrCodeData, setIsQrCodeData] = useState<any>()

  useEffect(() => {
    if (isQrCodeVisible) {
      setIsQrCodeData('')
      dispatch(getVisibleQrCode())
        .then(unwrapResult)
        .then((v) => {
          setIsQrCodeData(v)
        })
      isPeopleFace()
    }
  }, [isQrCodeVisible])

  useEffect(() => {
    if (isQrCodeVisible) {
      timer = setInterval(() => {
        isPeopleFace()
      }, 20000)
    } else {
      timer && clearInterval(timer)
    }
  }, [dispatch, isQrCodeVisible])

  const isPeopleFace = () => {
    dispatch(getVisibleIsPeopleFace())
      .then(unwrapResult)
      .then((v) => {
        if (v?.sfsl == 1) {
          timer && clearInterval(timer)
          setIsQrCodeVisible(false)
        }
      })
  }

  const getPage = () => {
    setPageLoading(true)
    dispatch(invoicePageAsync(params))
      .then(unwrapResult)
      .then((res) => {
        onResize() // 初始化设置高度
        window.addEventListener('resize', onResize)
        const data = {
          total: res?.invoiceDetailsVO?.total,
          items: res?.invoiceDetailsVO?.records,
          invoiceAmountSum: res?.invoiceAmountSum,
        }
        setPage(data)
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (params.current !== 0) {
      getPage()
      // sessionStorage.setItem(location.pathname, JSON.stringify({ ...params }))
    }
  }, [params])

  // useImperativeHandle(ref, () => ({
  //   reload: () => {
  //     dispatch(getPaymentList(params))
  //   },
  // }))

  // 发票种类：
  // p:电子增值税普通发票；
  // c:增值税普通发票(纸票)；
  // s:增值税专用发票；
  // e:收购发票(电子)；
  // f:收购发票(纸质)；
  // r:增值税普通发票(卷式)；
  // b:增值税电子专用发票；
  // j:机动车销售统一发票；
  // u:二手车销售统一发票
  useEffect(() => {
    if (ticketVisible) {
      dispatch(getTicketNumAsync({ supplierId: 1 }))
        .then(unwrapResult)
        .then((v: any) => {
          setNuoNuoCode(v.code)
          if (v.code == 'E0000') {
            const p = v?.result
            setPText(p)
          } else {
            setText(v.describe)
          }
        })
    }
  }, [ticketVisible])

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const successAction = () => {
    notification.success({
      message: '操作成功',
    })
    getPage()
  }

  const exportExcel = async (v: number) => {
    const res =
      v == 1
        ? await dispatch(
            invoiceExportAsync({
              ...params,
            })
          ).then(unwrapResult)
        : await dispatch(
            exportListHzAsync({
              ...params,
            })
          ).then(unwrapResult)

    const content = res?.outputStream
    const blob = new Blob([content])
    const fileName = res?.fileName
    const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
    const elink = document.createElement('a')
    if ('download' in elink) {
      elink.download = fileName
      elink.style.display = 'none'
      elink.href = selfURL['createObjectURL'](blob)
      document.body.appendChild(elink)
      // 触发链接
      elink.click()
      selfURL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    } else {
      return (navigator as any).msSaveBlob(blob, fileName)
    }

    // notification.success({
    //   message: '导出成功',
    //   duration: 3,
    // })
  }

  const downLoadFile = (id: any) => {
    dispatch(getDzfpExportAsync(id))
      .then(unwrapResult)
      .then((res: any) => {
        setBase64PdfData('data:application/pdf;base64,' + res.data)
        setIsModalVisible(true)
        setPageLoading(false)
      })
      .catch((v) => {
        setPageLoading(false)
      })
  }

  useEffect(() => {
    if (!isModalVisible) {
      setBase64PdfData('')
    }
  }, [isModalVisible])

  // 前置机模式的开票结果查询接口
  const selectDzfpResultForMbfe = (
    bodyJson: any,
    serverType: serverType,
    responseString?: string
  ) => {
    dispatch(
      getInvoicingStatusForMbfeAsync({
        serverType,
        ...bodyJson,
        responseString: encodeURIComponent(
          responseString
            ?.replace('>"', '>')
            ?.replace('"<', '<')
            .replace(/\\/g, '') || ''
        ),
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          MbfeService(
            'HX_ZJ_CZPJ',
            {
              trade_args: [
                'dzfpOpen',
                res?.url,
                res?.requestString,
                'nodata',
                'nodata',
              ],
              message: '查询开票结果',
            },
            (call: any, err) => {
              if (!err) {
                selectDzfpResultForMbfe(
                  bodyJson,
                  'response',
                  JSON.stringify(call.netmessage.result)
                )
              }
            }
          )
        } else {
          successAction()
        }
      })
  }
  // 前置机模式的红冲结果查询接口
  const selectDzfpFlushResultForMbfe = (
    bodyJson: any,
    serverType: serverType,
    responseString?: string
  ) => {
    dispatch(
      checkFlushForMbfeAsync({
        serverType,
        ...bodyJson,
        responseString: encodeURIComponent(
          responseString
            ?.replace('>"', '>')
            ?.replace('"<', '<')
            .replace(/\\/g, '') || ''
        ),
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          MbfeService(
            'HX_ZJ_CZPJ',
            {
              trade_args: [
                'dzfpOpen',
                res?.url,
                res?.requestString,
                'nodata',
                'nodata',
              ],
              message: '查询开票结果',
            },
            (call: any, err) => {
              if (!err) {
                selectDzfpFlushResultForMbfe(
                  bodyJson,
                  'response',
                  JSON.stringify(call.netmessage.result)
                )
              } else {
                setPageLoading(false)
              }
            }
          )
        } else {
          setPageLoading(false)
          successAction()
        }
      })
      .catch((v) => {
        getPage()
        setPageLoading(false)
      })
      .finally(() => {
        if (serverType === 'response') {
          setLoading(false)
        }
      })
  }
  // 前置机模式的申请红冲接口
  const sendDzfpFlushForMbfe = (
    bodyJson: any,
    serverType: serverType,
    responseString?: string
  ) => {
    dispatch(
      cancelInvoiceMbfeAsync({
        serverType,
        ...bodyJson,
        responseString: encodeURIComponent(
          responseString
            ?.replace('>"', '>')
            ?.replace('"<', '<')
            .replace(/\\/g, '') || ''
        ),
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          MbfeService(
            'HX_ZJ_CZPJ',
            {
              trade_args: [
                'dzfpOpen',
                res?.url,
                res?.requestString,
                'nodata',
                'nodata',
              ],
              message: '申请红冲',
            },
            (call: any, err) => {
              if (!err) {
                sendDzfpFlushForMbfe(
                  bodyJson,
                  'response',
                  JSON.stringify(call.netmessage.result)
                )
              } else {
                setPageLoading(false)
              }
            }
          )
        } else {
          setPageLoading(false)
          successAction()
        }
      })
      .catch((v) => {
        setPageLoading(false)
      })
      .finally(() => {
        if (serverType === 'response') {
          setLoading(false)
        }
      })
  }
  // 前置机模式的票据日对账
  const ticketAccountCheckForMbfe = (
    bodyJson: any,
    serverType: serverType,
    responseString?: string
  ) => {
    dispatch(
      ticketAccountCheckMbfeAsync({
        serverType,
        ...bodyJson,
        responseString: encodeURIComponent(
          responseString
            ?.replace('>"', '>')
            ?.replace('"<', '<')
            .replace(/\\/g, '') || ''
        ),
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          MbfeService(
            'HX_ZJ_CZPJ',
            {
              trade_args: [
                'dzfpOpen',
                res?.url,
                res?.requestString,
                'nodata',
                'nodata',
              ],
              message: '票据日对账',
            },
            (call: any, err) => {
              if (!err) {
                ticketAccountCheckForMbfe(
                  bodyJson,
                  'response',
                  JSON.stringify(call.netmessage.result)
                )
              } else {
                setPageLoading(false)
              }
            }
          )
        } else {
          setPageLoading(false)
          successAction()
        }
      })
      .catch((v) => {
        setPageLoading(false)
      })
      .finally(() => {
        if (serverType === 'response') {
          setLoading(false)
        }
      })
  }
  const onResize = () => {
    const table = document.getElementById(
      status == RecipeStatus.invoice ? 'tableHeightInvoiveRecord' : ''
    )
    if (table) {
      const height = table.offsetHeight - 60 - 80 - 20
      setScrollHeight(height)
    } else {
      return '100%'
    }
  }

  return (
    <Col
      style={{
        backgroundColor: theme.pn,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 10px)',
        marginTop: '10px',
        overflow: 'hidden',
        padding: '10px 15px 0 15px',
      }}
    >
      <Query
        onAction={(args) => {
          if (args?.type == 'ticketAccountCheck') {
            setTimeVisible(true)
          }
        }}
        status={status}
        type={type || '0'}
        onValueChange={(v) => {
          setParams({
            ...params,
            ...v,
            current: 1,
            endTime: v.actionTimeTail,
            startTime: v.actionTimeHead,
            name: patientId ? patientId : v.name,
          })
        }}
        export={(v) => {
          exportExcel(v)
        }}
        ticketsLeftNum={() => {
          setTicketVisible(true)
        }}
        notInvoicedQuery={() => {
          // 未开票查询
          setNotInvoicedQueryVisible(true)
        }}
      />
      <div
        id={status == RecipeStatus.invoice ? 'tableHeightInvoiveRecord' : ''}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <EditableList
          style={{
            width: '100%',
            height: '89%',
            overflow: 'hidden',
          }}
          scroll={{ y: Number(scrollHeight) }}
          loading={pageLoading}
          page={{
            current: params.current,
            size: params.size,
            total: page.total,
            items: page.items,
          }}
          columns={Columns(params.size * (params.current - 1), (action, t) => {
            switch (action) {
              case 'downLoad':
                downLoadFile(t?.id)
                setPageLoading(true)
                break
              case 'print':
                setPrintId(t?.id)
                setPrintTemplateCategory(PrintTemplateCategory.Gzddy)
                break
              case 'result':
                //厂商ID为4:浙江财政票据时，使用前置机方式发起交易，其他均采用服务器模式发送交易
                if (credentials?.dzfpSupplierId == '4') {
                  selectDzfpResultForMbfe(
                    {
                      invoiceSerialNum: t.eserialNo,
                      id: t?.id,
                    },
                    'requestString'
                  )
                } else {
                  dispatch(
                    getInvoicingStatusAsync({
                      invoiceSerialNum: t.eserialNo,
                      id: t?.id,
                    })
                  )
                    .then(unwrapResult)
                    .then(() => {
                      successAction()
                    })
                }
                break
              case 'apply':
                //厂商ID为4:浙江财政票据时，使用前置机方式发起交易，其他均采用服务器模式发送交易
                if (credentials?.dzfpSupplierId == '4') {
                  setPageLoading(true)
                  sendDzfpFlushForMbfe(
                    {
                      id: t.id,
                      recipeIds: t.recipeIds.split(','),
                      treatmentId: t.treatmentId,
                    },
                    'requestString'
                  )
                } else {
                  setPageLoading(true)
                  dispatch(
                    cancelInvoiceAsync({
                      id: t.id,
                      recipeIds: t.recipeIds.split(','),
                      treatmentId: t.treatmentId,
                    })
                  )
                    .then(unwrapResult)
                    .then(() => {
                      setPageLoading(false)
                      successAction()
                    })
                    .catch((v) => {
                      setPageLoading(false)
                      if (String(v?.message).indexOf('人脸认证失效') != -1) {
                        setIsQrCodeVisible(true)
                      }
                    })
                }
                break
              case 'detail':
                window.open(t.epaperPdfUrl, '_blank')
                break
              case 'reason':
                setFailReason(t.failCause)
                setReasonModal(true)
                break
              case 'again':
                form.setFieldsValue({
                  phone: t.esendPhone,
                  email: t.esendMail,
                })
                setRedeliveryData({
                  invoiceCode: t.invoiceCode,
                  invoiceNum: t.invoiceNum,
                  email: t.esendMail,
                  phone: t.esendPhone,
                })
                setAgainModal(true)
                break
              case 'detailDel':
                dispatch(invoiceDetailDeleteAsync(t.id)).then(() => {
                  successAction()
                })
                break
              case 'detailNo':
                dispatch(
                  invoiceDetailStatusAsync({
                    id: t.id,
                    state: 5,
                  })
                ).then(() => {
                  successAction()
                })
                break
              case 'detailCancel':
                dispatch(
                  invoiceDetailStatusAsync({
                    id: t.id,
                    state: 1,
                  })
                ).then(() => {
                  successAction()
                })
                break
              case 'checkflush':
                //厂商ID为4:浙江财政票据时，使用前置机方式发起交易，其他均采用服务器模式发送交易
                if (credentials?.dzfpSupplierId == '4') {
                  setPageLoading(true)
                  selectDzfpFlushResultForMbfe(
                    {
                      id: t?.id,
                      supplierId: t?.supplierId,
                    },
                    'requestString'
                  )
                } else {
                  setPageLoading(true)
                  dispatch(
                    checkFlushAsync({
                      id: t.id,
                      supplierId: t?.supplierId,
                    })
                  )
                    .then(unwrapResult)
                    .then(() => {
                      setPageLoading(false)
                      successAction()
                    })
                    .catch((vv) => {
                      getPage()
                      setPageLoading(false)
                    })
                }

                break
            }
          })}
          onChangePage={(current, size) =>
            setParams({
              ...params,
              current,
              size: size || 10,
            })
          }
          onRow={() => ({
            onDoubleClick: () => {
              return
            },
          })}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1} align='center'>
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2}
                  align='center'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={3}
                  align='center'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={4}
                  align='center'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={5}
                  align='center'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={6}
                  align='center'
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={7} align='center'>
                  {page?.invoiceAmountSum}
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={8}
                  align='center'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={9}
                  align='center'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={10}
                  align='center'
                ></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </div>
      <Modal
        title='失败原因'
        visible={reasonModal}
        onCancel={() => {
          setReasonModal(false)
          form.resetFields()
        }}
        footer={null}
      >
        {failReason}
        <Row justify='end'>
          <Button type='primary' onClick={() => setReasonModal(false)}>
            确认
          </Button>
        </Row>
      </Modal>
      <Modal
        title='重新发送'
        width={1200}
        visible={againModal}
        onCancel={() => {
          setAgainModal(false)
          setLoading(false)
          form.resetFields()
        }}
        footer={null}
      >
        <Form
          {...layout}
          form={form}
          autoComplete='off'
          name='invoice-hooks'
          style={{ width: '100%' }}
          initialValues={{
            openType: 0,
          }}
          onFinish={(values) => {
            setLoading(true)
            if (!values.phone && !values.email) {
              notification.error({
                message: '至少填写一个发票接收人信息',
              })
              setLoading(false)
              return
            }
            setLoading(false)
            const data = { ...redeliveryData, ...values }
            dispatch(redeliveryAsync(data))
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: '操作成功',
                })
                form.resetFields()
                setAgainModal(false)
                getPage()
              })
              .finally(() => {
                setLoading(false)
              })
          }}
        >
          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                name='phone'
                label='接收人手机号'
                getValueFromEvent={stringTrim}
                validateTrigger='onBlur'
                rules={[
                  {
                    pattern: /^1\d{10}$/,
                    message: '请输入正确手机号',
                  },
                ]}
              >
                <Input placeholder='请输入手机号' maxLength={11} allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='email'
                validateTrigger='onBlur'
                rules={[{ type: 'email' }]}
                label='接收人电子邮箱地址'
                getValueFromEvent={stringTrim}
              >
                <Input placeholder='请输入电子邮箱地址' allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='end'>
            <Space>
              <Button
                onClick={() => {
                  setAgainModal(false)
                  form.resetFields()
                  setLoading(false)
                }}
              >
                取消
              </Button>
              <Button type='primary' loading={loading} htmlType='submit'>
                确认
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
      <Modal
        width={610}
        title='余票查询'
        visible={ticketVisible}
        onCancel={() => {
          setTicketVisible(false)
        }}
        footer={null}
      >
        <div>
          {nuonuoCode == 'E0000' ? (
            pText?.map((item, index) => {
              return (
                <div key={index}>
                  <p>更新时间：{item?.updateTime}</p>
                  <p>
                    余票：{item?.remainNum} 发票代码：{item?.typeCode}{' '}
                    起始号码：{item.invoiceNumStart} 终止号码：
                    {item.invoiceNumEnd}
                  </p>
                </div>
              )
            })
          ) : (
            <p>{text}</p>
          )}
        </div>
      </Modal>

      <NotInvoicedQueryModal
        status={status}
        visible={notInvoicedQueryVisible}
        onCancel={() => {
          setNotInvoicedQueryVisible(false)
        }}
        onOk={() => {
          setNotInvoicedQueryVisible(false)
        }}
      ></NotInvoicedQueryModal>
      <Modal
        title='二维码'
        footer={null}
        visible={isQrCodeVisible}
        onCancel={() => setIsQrCodeVisible(false)}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isQrCodeData && (
            <Qrcode
              id='qrcode2'
              value={isQrCodeData?.ewm}
              style={{
                width: 240,
                height: 240,
              }}
            />
          )}
        </div>
      </Modal>
      <PrintTemplateModal
        visible={!!printTemplateCategory}
        templateCategory={printTemplateCategory}
        invoiceId={printId}
        onCancel={() => setPrintTemplateCategory(undefined)}
        onOk={() => setPrintTemplateCategory(undefined)}
      />
      <Modal
        title={'发票预览'}
        visible={isModalVisible}
        footer={null}
        width={'100%'}
        onCancel={() => {
          setIsModalVisible(false)
          setBase64PdfData('')
          // location.reload()
          getPage()
        }}
      >
        <div>
          {/* 使用 <embed> 元素来嵌入 PDF */}
          {/*<embed src={base64PdfData} type="application/pdf" width="100%" height="500px" />*/}

          {/* 或者使用 <object> 元素 */}
          {base64PdfData && (
            <>
              <object
                key={base64PdfData}
                data={base64PdfData}
                type='application/pdf'
                width='100%'
                height='700px'
              >
                PDF viewer not available. You can download the PDF from{' '}
                <a href={base64PdfData}>here</a>.
              </object>
            </>
          )}
        </div>
      </Modal>
      <Modal
        title='选择日期'
        // okText='导出'
        onCancel={() => {
          setTimeVisible(false)
        }}
        onOk={() => {
          if (checkAccountTime) {
            const args = { date: checkAccountTime }
            ticketAccountCheckForMbfe(args, 'requestString')
            setTimeVisible(false)
          } else {
            notification.info({
              message: '请选择时间',
            })
          }
        }}
        visible={timeVisible}
        centered
        bodyStyle={{
          display: 'flex',
          justifyContent: 'center',
          height: 150,
          alignItems: 'center',
        }}
        destroyOnClose
      >
        <div>
          <DatePicker
            onChange={(v) => {
              if (v) {
                setCheckAccountTime(moment(v).format('YYYY-MM-DD 00:00:00'))
              } else {
                setCheckAccountTime(null)
              }
            }}
          />
        </div>
      </Modal>
    </Col>
  )
}

export const InvoiveRecords = forwardRef(InvoiveRecord)
